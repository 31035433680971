import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import LeftBar from 'components/LeftBar';

const leftMenuItemsList = [
  {
    title: 'Q&A',
    icon: <HelpCenterIcon />
  }
];

const Questions = () => {
  return (
    <>
      <LeftBar
        topNavbarIcon={<HelpRoundedIcon fontSize="small" />}
        topNavbarLabel="Questions"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Questions;
