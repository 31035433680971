import { logout as logoutService } from 'services/user-service';
import { AUTH_TOKEN_COOKIE_NAME } from 'constants/cookies';
import { deleteCookie } from 'utils/cookies';

export const logout = (action) => {
  logoutService().then(() => {
    deleteCookie(AUTH_TOKEN_COOKIE_NAME);
  });
  action();
};
