import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import DocumentScannerRoundedIcon from '@mui/icons-material/DocumentScannerRounded';

import LeftBar from 'components/LeftBar';
// document_info
import { document_info } from './document-utils';
import {
  getDocumentInfo as getDocumentInfoRx,
  setLoading as setLoadingRx
} from 'reducers/documentInfo';

const leftMenuItemsList = [
  {
    title: 'All Documents',
    icon: <DocumentScannerRoundedIcon />,
    path: ''
  }
];

const Documents = () => {
  const dispatch = useDispatch();
  const document_info_data = useSelector(
    (state) => state.documentInfo.document_info
  );

  useEffect(() => {
    if (!document_info_data?.length) {
      dispatch(setLoadingRx(true));
      document_info((data) => dispatch(getDocumentInfoRx(data)));
    }
  }, []);

  return (
    <>
      <LeftBar
        topNavbarIcon={<FolderIcon fontSize="small" />}
        topNavbarLabel="Documents"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Documents;
