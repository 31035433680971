import axios from 'axios';
import { AUTH_TOKEN_COOKIE_NAME } from 'constants/cookies';

/**
 * Given the url, method and the data, we'll send the request. Also will attach the token if it exists.
 * @param {string} url URL of the endpoint. If hitting our API, then we can pass the relative path
 * @param {string} method Request HTTP Type. GET/POST/PUT/PATCH/DELETE
 * @param {object} data The payload for the request
 * @param {object} options Additional options to pass down to the request
 * @returns a Promise of the request
 */
const API_URL = process.env.REACT_APP_API_URL;

export const http = (url, method = 'GET', data = {}, options = {}) => {
  const endpoint = url.substring(0, 1) === '/' ? API_URL + url : url;
  const authTokenValue = localStorage.getItem(AUTH_TOKEN_COOKIE_NAME);
  // if (authTokenValue) {
  options.headers = {
    ...options.headers,
    Authorization: `Bearer ${authTokenValue}`
  };
  return axios({
    method,
    url: endpoint,
    [method === 'POST' ? 'data' : 'params']: data,
    ...options
  })
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

// GET
export const get = (url, params) => {
  return http(url, 'GET', params);
};

// DELETE
export const del = (url, params) => {
  return http(url, 'DELETE', params);
};

// PATCH
export const patch = (url, params) => {
  return http(url, 'PATCH', params);
};

// POST
export const post = (url, data) => {
  return http(url, 'POST', data);
};

// PUT
export const put = (url, data) => {
  return http(url, 'PUT', data);
};
