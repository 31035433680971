import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from 'components/Typography/Typography';
import TextField from 'components/TextField/TextField';
import TextArea from 'components/TextArea/TextArea';
import Button from 'components/Button/Button';

// icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const Item = styled(Paper)(({ theme }) => ({
  background: 'transparent',
  color: '#fff',
  fontSize: '16px'
}));

const ContactForm = () => {
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item md={6}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', marginBottom: '15px' }}
          >
            Contact Us
          </Typography>
          <Typography variant="subtitle1" sx={{ marginBottom: '15px' }}>
            Feel free to contact us any time. We will get back to you as soon as
            we can!
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField variant="standard" color="info" label="Full name" />
            </Grid>
            <Grid item xs={12}>
              <TextField variant="standard" color="info" label="Email" />
            </Grid>
            <Grid item xs={12}>
              <TextArea variant="standard" color="info" label="Message" />
            </Grid>
          </Grid>

          <Button sx={{ width: '100%', marginTop: '45px' }}>Send</Button>
        </Grid>
        <Grid item md={6}>
          <Box
            sx={{
              backgroundColor: '#fff5f8',
              padding: '1.9rem',
              width: { sm: '100%', md: '350px' },
              height: 'fit-content'
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: '#000', marginBottom: '25px', fontWeight: 'bold' }}
            >
              Info
            </Typography>
            <Stack spacing={3}>
              <Item
                elevation={0}
                sx={{ display: 'flex', columnGap: '10px', color: '#000' }}
              >
                <EmailOutlinedIcon />
                <Typography>support@yogaimmigration.com</Typography>
              </Item>
              {/* PHONE NUMBER */}
              {/* <Item
                elevation={0}
                sx={{ display: 'flex', columnGap: '10px', color: '#000' }}
              >
                <LocalPhoneOutlinedIcon />
                <Typography>+1 (123) 123-1256</Typography>
              </Item> */}
              <Item
                elevation={0}
                sx={{ display: 'flex', columnGap: '10px', color: '#000' }}
              >
                <BusinessOutlinedIcon />
                <Typography>Toronto, Canada</Typography>
              </Item>
              <Item
                elevation={0}
                sx={{ display: 'flex', columnGap: '10px', color: '#000' }}
              >
                <AccessTimeOutlinedIcon />
                <Typography>09:00 - 18:00</Typography>
              </Item>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ContactForm;
