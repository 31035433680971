export const badge = {
  styleOverrides: {
    colorSuccess: {
      backgroundColor: '#b8ffd4',
      color: '#097333'
    },
    colorError: {
      backgroundColor: '#FFDFDF',
      color: '#FF0000'
    }
  }
};
