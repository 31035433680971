import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/Table/Table';
import { CircularLoader } from 'components/Loader/Loader';
import Badge from 'components/Badge/Badge';

const headers = ['Required Documents', 'Status'];

const ViewDocuments = () => {
  let data = [];
  const document_info = useSelector(
    (state) => state.documentInfo.document_info
  );
  const isLoading = useSelector((state) => state.documentInfo.isLoading);

  if (document_info?.length > 0) {
    document_info?.map((document) => {
      data.push({
        document: document.document,
        status:
          document.status === 'on' ? (
            <Badge label="Received" color="success" size="small" />
          ) : (
            <Badge label="Not Received" color="error" size="small" />
          )
      });
    });
  }
  return (
    <Fragment>
      {isLoading && <CircularLoader />}
      {!isLoading && <Table headers={headers} data={data} />}
    </Fragment>
  );
};

export default ViewDocuments;
