import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  visaStatus_data: []
};

export const visaStatusSlice = createSlice({
  name: 'visaStatus',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getVisaStatus: (state, action) => {
      state.isLoading = true;
      state.visaStatus_data = action.payload;
      state.isLoading = false;
    },
    clearVisaStatus: () => initialState
  }
});

export const { setLoading, getVisaStatus, clearVisaStatus } =
  visaStatusSlice.actions;

export default visaStatusSlice.reducer;
