import { createBrowserRouter } from 'react-router-dom';
// components
import Authentication from 'pages/Authentication';
import Home from 'pages/Home/Home';
import Profile from 'pages/Profile/Profile';
import ProfileDetails from 'pages/Profile/Details/ProfileDetails';
import EditProfileDetails from 'pages/Profile/EditDetails/EditProfileDetails';
import Documents from 'pages/Documents/Documents';
import ViewDocuments from 'pages/Documents/ViewDocuments/ViewDocuments';
import Questions from 'pages/Questions/Questions';
import QueAns from 'pages/Questions/QueAns/QueAns';
import Contact from 'pages/Contact/Contact';
import ContactForm from 'pages/Contact/ContactForm/ContactForm';
import Payment from 'pages/Payment/Payment';
import PaymentInfo from 'pages/Payment/payment-info/payment-info';
import Visa from 'pages/Visa/Visa';
import BasePage from 'pages/Visa/BasePage/BasePage';
import ErrorPage from 'components/App/ErrorPage';
import { checkAuthLoader, ActiveRouteProtect } from 'utils/auth';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Authentication />
  },
  {
    path: '/home',
    element: <Home />,
    loader: checkAuthLoader
  },
  {
    path: '/profile',
    element: (
      <ActiveRouteProtect>
        <Profile />
      </ActiveRouteProtect>
    ),
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <ProfileDetails />
      },
      {
        path: 'edit',
        element: <EditProfileDetails />
      }
    ]
  },
  {
    path: '/documents',
    element: (
      <ActiveRouteProtect>
        <Documents />
      </ActiveRouteProtect>
    ),
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <ViewDocuments />
      }
    ]
  },
  {
    path: '/questions',
    element: (
      <ActiveRouteProtect>
        <Questions />
      </ActiveRouteProtect>
    ),
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <QueAns />
      }
    ]
  },
  {
    path: '/contact',
    element: <Contact />,
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <ContactForm />
      }
    ]
  },
  {
    path: '/payment',
    element: <Payment />,
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <PaymentInfo />
      }
    ]
  },
  {
    path: '/visa',
    element: (
      <ActiveRouteProtect>
        <Visa />
      </ActiveRouteProtect>
    ),
    loader: checkAuthLoader,
    children: [
      {
        index: true,
        element: <BasePage />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorPage />
  }
]);
