import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField as TextFieldMui } from '@mui/material';
import { useFormContext } from 'react-hook-form';

const TextField = ({
  name,
  id,
  hint,
  label,
  autoComplete,
  autoFocus,
  color,
  defaultValue,
  disabled,
  isError,
  onChange,
  onBlur,
  value,
  variant,
  className,
  type,
  sx,
  fullWidth,
  required,
  ...props
}) => {
  const { register } = useFormContext() || {};

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  let textFieldProps = {
    name,
    id,
    helperText: hint,
    label,
    autoComplete,
    autoFocus,
    color,
    defaultValue,
    disabled,
    error: isError,
    onBlur,
    value,
    variant,
    type,
    multiline: type === 'textarea',
    className,
    required,
    sx: { ...sx, width: '100%' },
    fullWidth,
    onChange: handleChange,
    ...props
  };

  if (register) {
    textFieldProps = {
      ...textFieldProps,
      ...register(name)
    };
  }
  return <TextFieldMui {...textFieldProps} />;
};

TextField.propTypes = {
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning'
  ])
};

TextField.defaultProps = {
  variant: 'outlined',
  color: 'primary',
  label: 'Default'
};

export default TextField;
