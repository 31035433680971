import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import LeftBar from 'components/LeftBar';

const leftMenuItemsList = [
  {
    title: 'Ask Question',
    icon: <ContactPhoneRoundedIcon />
  }
];

const Contact = () => {
  return (
    <>
      <LeftBar
        topNavbarIcon={<EmailRoundedIcon fontSize="small" />}
        topNavbarLabel="Contact"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Contact;
