import React, { useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout } from 'pages/Logout/logout-utils';
import { useDispatch, useSelector } from 'react-redux';
import { logout as logoutRx } from 'reducers/auth';

import { Link as LinkRouter } from 'react-router-dom';

import AdbIcon from '@mui/icons-material/Adb';
import HomeIcon from '@mui/icons-material/Home';

import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { getLocalStorage } from 'utils/localStorage';

// user information
import { profile_info, userProfile_info } from 'pages/Profile/profile-utils';
import {
  getProfileInfo as getProfileInfoRx,
  setLoading as setLoadingRx
} from 'reducers/profile';

// user profile information
import {
  getUserProfileInfo as getUserProfileInfoRx,
  setLoading as setLoadingUserProfileRx
} from 'reducers/userProfile';

import YogaLogo from 'src/assets/canada_logo_white.png';

import styles from './NavBar.module.scss';

const settings = ['Profile', 'Documents', 'Visa', 'Logout'];

const NavBar = (props) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const userName = getLocalStorage('user_name');
  let history = useNavigate();
  const userProfile = useSelector(
    (state) => state.userProfile.userProfile_info
  );
  const user_info = useSelector((state) => state.profile.profile_info);
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userIconClickHandler = (setting) => {
    switch (setting) {
      case 'Profile':
        history('/profile');
        break;
      case 'Documents':
        history('/documents');
        break;
      case 'Visa':
        history('/visa');
        break;
      case 'Logout':
        logout(() => dispatch(logoutRx()));
        history('/');
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    if (!user_info?.id && !userProfile?.id) {
      dispatch(setLoadingRx(true));
      dispatch(setLoadingUserProfileRx(true));
      profile_info((data) => dispatch(getProfileInfoRx(data)));
      userProfile_info((data) => dispatch(getUserProfileInfoRx(data)));
    }
  }, []);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* Logo - larger then 'md' screens */}
            {props.leftMenu}
            <img src={YogaLogo} className={styles['letterLogo']} />
            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              Yoga Immigration
            </Typography>
            {/* Logo - 'sm' screens */}
            <img
              src={YogaLogo}
              className={styles[('letterLogo', 'hideInLg')]}
            />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              Yoga Imm
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
                marginLeft: '-155px'
              }}
            >
              <Breadcrumbs label={props.label} icon={props.labelIcon} />
            </Box>
            <Box sx={{ marginRight: 4 }}>
              <LinkRouter to="/home">
                <HomeIcon sx={{ fontSize: '30px', color: '#fff' }} />
              </LinkRouter>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userName} src="#" sx={{ bgcolor: '#8B1212' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography
                      onClick={() => userIconClickHandler(setting)}
                      textAlign="center"
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default NavBar;
