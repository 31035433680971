import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  document_info: []
};

export const documentInfoSlice = createSlice({
  name: 'documentInfo',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getDocumentInfo: (state, action) => {
      state.isLoading = true;
      state.document_info = action.payload;
      state.isLoading = false;
    },
    clearDocumentInfo: () => initialState
  }
});

export const { setLoading, getDocumentInfo, clearDocumentInfo } =
  documentInfoSlice.actions;

export default documentInfoSlice.reducer;
