import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Toolbar } from '@mui/material';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';

import LeftBar from 'components/LeftBar';
// payment_info
import { payment_info } from './payment-utils';
import {
  getPaymentInfo as getPaymentInfoRx,
  setLoading as setLoadingRx
} from 'reducers/paymentInfo';

const leftMenuItemsList = [
  {
    title: 'Payments',
    icon: <ReceiptRoundedIcon />
  }
];

const Payment = () => {
  const dispatch = useDispatch();
  const payment_info_data = useSelector(
    (state) => state.paymentInfo.payment_info
  );

  useEffect(() => {
    if (!payment_info_data.length) {
      dispatch(setLoadingRx(true));
      payment_info((data) => dispatch(getPaymentInfoRx(data)));
    }
  }, []);

  return (
    <>
      <LeftBar
        topNavbarIcon={<PaidRoundedIcon fontSize="small" />}
        topNavbarLabel="Payment"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Payment;
