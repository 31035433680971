import { CardMedia as CardMediaMui } from '@mui/material';

const CardMedia = ({ component, image, src, alt, sx, ...props }) => {
  const cardMediaProps = {
    component,
    image,
    src,
    sx: { height: 130, width: 130, margin: 'auto' },
    alt,
    ...props,
  };
  return <CardMediaMui {...cardMediaProps} />;
};

export default CardMedia;
