import { Outlet } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';

import LeftBar from 'components/LeftBar';

const leftMenuItemsList = [
  {
    title: 'Visa Status',
    icon: <VerifiedUserRoundedIcon />,
    path: ''
  }
];

const Visa = () => {
  return (
    <>
      <LeftBar
        topNavbarIcon={<VerifiedRoundedIcon fontSize="small" />}
        topNavbarLabel="Visa Status"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Visa;
