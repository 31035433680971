import { getUserInfo, getUserProfileInfo } from 'services/user-service';
import { setLocalStorage } from 'utils/localStorage';

// Client Information
export const profile_info = (action) => {
  getUserInfo().then((data) => {
    setLocalStorage(
      'isActive',
      data?.user_infos?.is_activated === 'active' || false
    );
    action(data?.user_infos);
  });
};

// Client Profile
export const userProfile_info = (action) => {
  getUserProfileInfo().then((data) => {
    action(data?.profile);
  });
};
