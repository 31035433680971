import { configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import profile from './profile';
import userProfile from './userProfile';
import paymentInfo from './paymentInfo';
import documentInfo from './documentInfo';
import visaStatus from './visaStatus';

export const store = configureStore({
  reducer: {
    auth,
    profile,
    userProfile,
    paymentInfo,
    documentInfo,
    visaStatus
  }
});
