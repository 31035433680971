import React from 'react';

const ErrorPage = () => {
  return (
    <>
      <h1>Something went wrong.</h1>
      <a href="/home">Go back to Home</a>
      <br />
      <h3>
        Note: Still the page give error, refersh the page or loggout and login
        again. Thank you for your patient
      </h3>
    </>
  );
};

export default ErrorPage;
