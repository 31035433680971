import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';

// components
import TextField from 'components/TextField';
import Button from 'components/Button';
import Form from 'components/Form/Form';
import Typography from 'components/Typography/Typography';

import { login as loginRx } from 'reducers/auth';
import { clearProfile } from 'reducers/profile';
import { clearDocumentInfo } from 'reducers/documentInfo';
import { clearPaymentInfo } from 'reducers/paymentInfo';
import { clearUserProfileInfo } from 'reducers/userProfile';
import { clearVisaStatus } from 'reducers/visaStatus';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_LANDING_PAGE } from 'constants/urls';
import { login } from './login-utils';
import { setLocalStorage, deleteLocalStorage } from 'utils/localStorage';

// styles
import styles from './Login.module.scss';

const Login = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const [isValid, setIsValid] = useState(true);

  const handleSubmit = (data) => {
    login(data.email, data.password, setIsValid, (data) => {
      dispatch(loginRx(data));
    });
  };
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(clearProfile());
      dispatch(clearDocumentInfo());
      dispatch(clearPaymentInfo());
      dispatch(clearVisaStatus());
      dispatch(clearUserProfileInfo());
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    setLocalStorage('user_name', loggedInUser?.first_name || '');
    return <Navigate to={DEFAULT_LANDING_PAGE} />;
  } else {
    deleteLocalStorage('user_name');
    deleteLocalStorage('isActive');
  }

  return (
    <div className={styles.login}>
      <Form onSubmit={handleSubmit}>
        <Typography
          variant="h5"
          align="center"
          sx={{ margin: '0.5rem 0 1rem 0' }}
        >
          Login
        </Typography>

        <TextField
          label="Email"
          name="email"
          type="text"
          autoFocus={true}
          required
          sx={{ marginBottom: '20px' }}
        />
        <TextField label="Password" type="password" name="password" required />
        {!isValid && (
          <Typography variant="caption" sx={{ color: 'red' }}>
            Invalid *Email or *Password
          </Typography>
        )}
        {/* <Typography
          variant="caption"
          align="right"
          underline="none"
          sx={{ display: 'block', marginTop: '0.5rem' }}
        >
          <Link to="/" className={styles.link}>
            Forget Password
          </Link>
        </Typography> */}
        <Button
          variant="contained"
          sx={{ width: '100%', margin: '2rem 0 1rem 0' }}
          type="submit"
        >
          Login
        </Button>
      </Form>
    </div>
  );
};

export default Login;
