import { login as loginService, getLoggedInUser } from 'services/user-service';
import { AUTH_TOKEN_COOKIE_NAME } from 'constants/cookies';
import { setCookie } from 'utils/cookies';

export const login = (email, password, setValid, action) => {
  try {
    loginService(email, password).then((data) => {
      if (!data) {
        setValid(false);
        throw new Error('Invalid Email or Password ');
      }
      if (data.user) {
        setCookie(AUTH_TOKEN_COOKIE_NAME, data.token);
        getLoggedInUser().then((userInfo) => {
          action(userInfo);
        });
      }
    });
  } catch (err) {
    console.error(err);
  }
};

export const loggedIn = (action) => {
  getLoggedInUser().then((data) => {
    action(data);
  });
};
