import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import NavBar from 'components/NavBar';
import Menus from './Menus/Menus';
import Deactive_Menu from './Menus/Deactive_Menu';
import Loader from 'components/Loader/Loader';

const Home = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isProfileLoading = useSelector((state) => state.profile.isLoading);
  const user_info = useSelector((state) => state.profile.profile_info);

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <NavBar labelIcon={<HomeIcon fontSize="small" />} label="Home" />
      {isProfileLoading ? (
        <Loader />
      ) : (
        <>
          {!user_info && <Deactive_Menu />}
          {user_info && user_info?.is_activated === 'deactive' && (
            <Deactive_Menu />
          )}
          {user_info && user_info?.is_activated === 'active' && <Menus />}
        </>
      )}
    </>
  );
};

export default Home;
