import React from 'react';
import Dropdown from 'components/Dropdown/Dropdown';

import styles from './QueAns.module.scss';

const dropdown = [
  {
    title: 'What are the requirements for a Canadian student visa?',
    description:
      "To be eligible for a Canadian student visa, you'll need to provide proof of acceptance to a designated learning institution in Canada, proof of financial support, and a valid passport. You may also need to provide biometrics and undergo a medical examination. Our team of consultants can guide you through the entire application process and ensure that you have all the necessary documents."
  },
  {
    title:
      'How long does it take to process a Canadian student visa application?',
    description:
      "The processing time for a Canadian student visa application varies depending on the country you're applying from and the time of year. On average, it can take between two to four weeks to process a student visa application. However, it's important to apply well in advance of your intended start date, as processing times can be longer during peak seasons."
  },
  {
    title: 'Can I work while studying in Canada?',
    description:
      'Yes, international students with a valid study permit can work part-time on campus or off-campus during their studies. You may also be eligible for a post-graduation work permit after you complete your studies in Canada.'
  },
  {
    title:
      'How can Yoga Immigration help me with my Canadian student visa application?',
    description:
      "At Yoga Immigration, we have a team of experienced consultants who can guide you through the entire student visa application process. We'll help you prepare your documents, complete your application form, and provide guidance on any additional requirements such as biometrics or medical exams. We'll also keep you informed and updated throughout the process, and help you overcome any challenges that may arise."
  }
];

const QueAns = () => {
  return <Dropdown dropdown={dropdown} className={styles.dropDown} />;
};

export default QueAns;
