import React, { useState } from 'react';
import Form from 'components/Form/Form';
import { register as registerService } from 'services/user-service';
import { useNavigate } from 'react-router-dom';

import TextField from 'components/TextField';
import Button from 'components/Button';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import styles from './Register.module.scss';

const Register = () => {
  const [isRestrict, setIsRestrict] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = (data) => {
    const datas = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password_confirmation
    };

    registerService(datas).then((res) => {
      if (res.user) {
        navigate(0);
      }
    });
  };

  return (
    <div className={styles.register}>
      {isRestrict && (
        <AlertBanner
          heading="Access Restricted"
          content="You don't have access to create new account. Contact administration"
          variant="error"
        />
      )}
      {!isRestrict && (
        <Form onSubmit={handleSubmit}>
          <div className={styles['register-inner']}>
            <h3 className={styles['register-title']}>Create Account</h3>
            <div className={styles.name}>
              <TextField
                name="first_name"
                label="First name"
                autoFocus={true}
              />
              <TextField name="last_name" label="Last name" />
            </div>
            <TextField type="email" name="email" label="Email" />
            <TextField type="password" name="password" label="Password" />
            <TextField
              type="password"
              name="password_confirmation"
              label="Confirm Password"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ margin: '0 auto' }}
              className={styles.btn}
            >
              Register
            </Button>{' '}
          </div>
        </Form>
      )}
    </div>
  );
};

export default Register;
