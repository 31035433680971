import React from 'react';
import { Card as CardMui, CardContent, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LockPersonIcon from '@mui/icons-material/LockPerson';

const AlertBanner = ({
  heading,
  content,
  variant,
  bgColor,
  textColor,
  icon
}) => {
  switch (variant) {
    case 'warning':
      bgColor = '#fff8dd';
      textColor = '#FF7800';
      icon = <LockPersonIcon fontSize="large" sx={{ color: textColor }} />;
      break;
    case 'error':
      bgColor = '#fff5f8';
      textColor = '#f2416c';
      icon = <ErrorOutlineIcon fontSize="large" sx={{ color: textColor }} />;
      break;
  }

  return (
    <CardMui
      sx={{
        bgcolor: bgColor,
        boxShadow: 'none',
        width: { md: '550px', margin: 'auto' },
        paddingLeft: !icon ? '10px' : ''
      }}
    >
      <CardContent
        sx={{ display: 'flex', alignItems: 'center', columnGap: '.6rem' }}
      >
        {icon}
        <div>
          <Typography
            sx={{ fontSize: 16, fontWeight: '900' }}
            color={textColor}
            gutterBottom
          >
            {heading}
          </Typography>
          <Typography variant="body2" color={textColor} gutterBottom>
            {content}
          </Typography>
        </div>
      </CardContent>
    </CardMui>
  );
};

export default AlertBanner;
