import React from 'react';
import { Container, Grid, Link, CardActionArea } from '@mui/material';

import Card from 'components/Card';
import CardMedia from 'components/CardMedia';
import AlertBanner from 'components/AlertBanner/AlertBanner';

import { Link as LinkRouter } from 'react-router-dom';
import { WEBSITE_URL } from 'constants/urls';

// icons
import paymentIcon from 'pages/Home/Menus/img/payment.png';
import contactIcon from 'pages/Home/Menus/img/contact.png';

const Deactive_Menu = () => {
  return (
    <Container maxWidth="md" sx={{ marginTop: 5 }}>
      <Grid item xs={12} sx={{ margin: 'auto', marginBottom: '25px' }}>
        <AlertBanner
          heading="Locked Account"
          content="Your account is locked, Contact administration for support"
          variant="warning"
        />
      </Grid>
      <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
        <Grid item lg={3} md={3} xs={6}>
          <LinkRouter to="/payment" style={{ textDecoration: 'none' }}>
            <CardActionArea>
              <Card
                title="Payments"
                icon={
                  <CardMedia
                    component="img"
                    alt="menu-icon"
                    image={paymentIcon}
                  />
                }
              />
            </CardActionArea>
          </LinkRouter>
        </Grid>
        <Grid item lg={3} md={3} xs={6}>
          <LinkRouter to="/contact" style={{ textDecoration: 'none' }}>
            <CardActionArea>
              <Card
                title="Contact Us"
                icon={
                  <CardMedia
                    component="img"
                    alt="menu-icon"
                    image={contactIcon}
                  />
                }
              />
            </CardActionArea>
          </LinkRouter>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ margin: 'auto', marginTop: '25px' }}>
        <Grid
          item
          lg={6}
          md={6}
          xs={12}
          sx={{ width: { md: '550px', margin: 'auto' } }}
        >
          <Card
            title="Visit our website"
            text={
              <Link href={WEBSITE_URL} target="_blank">
                Yoga Immigration
              </Link>
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Deactive_Menu;
