import { Container } from '@mui/material';

const MenuWrapper = (props) => {
  return (
    <>
      <Container maxWidth='lg' sx={{ marginTop: 5 }}>
        {props.children}
      </Container>
    </>
  );
};

export default MenuWrapper;
