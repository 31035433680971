import React, { useState, useEffect } from 'react';
import { Link, Grid, CardActionArea } from '@mui/material';

import MenuWrapper from 'ui/MenuWrapper';
import Card from 'components/Card';
import CardMedia from 'components/CardMedia';
import Typography from 'components/Typography';
import PieChart from 'components/PieChart/PieChart';

import { Link as LinkRouter } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { CircularLoader } from 'components/Loader/Loader';

import profileIcon from 'pages/Home/Menus/img/profile.png';
import documentIcon from 'pages/Home/Menus/img/document.png';
import questionIcon from 'pages/Home/Menus/img/question.png';
import contactIcon from 'pages/Home/Menus/img/contact.png';
import paymentIcon from 'pages/Home/Menus/img/payment.png';
import processingIcon from 'pages/Home/Menus/img/processing.png';
import { WEBSITE_URL } from 'constants/urls';

import styles from './Menus.module.scss';

const Menus = () => {
  const [chartData, setChartData] = useState({
    labels: ['Completed', 'Pending'],
    datasets: [
      {
        data: [75, 25]
      }
    ]
  });
  const userProfile = useSelector(
    (state) => state.userProfile.userProfile_info
  );
  const isUserProfileLoading = useSelector(
    (state) => state.userProfile.isLoading
  );

  useEffect(() => {
    setChartData((prevData) => ({
      labels: prevData.labels,
      datasets: [
        {
          data: [
            userProfile?.application_status,
            100 - +userProfile?.application_status
          ]
        }
      ]
    }));
  }, [userProfile]);

  if (isUserProfileLoading) {
    return <CircularLoader />;
  }

  return (
    <div className={styles.menuContainer}>
      <MenuWrapper>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} xs={12}>
            <Card
              title="Updates"
              text={
                <Typography sx={{ color: '#dd1c1a' }}>
                  {userProfile?.update_message}
                </Typography>
              }
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Card
              title="Visit our website"
              text={
                <Link href={WEBSITE_URL} target="_blank">
                  Yoga Immigration
                </Link>
              }
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Card
              title="Application Progress"
              isChart={true}
              // text="Application progress report is not available"
            >
              <PieChart chartData={chartData} />
            </Card>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/profile" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Profile"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={profileIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/documents" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Documents"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={documentIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/questions" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Questions"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={questionIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/contact" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Contact Us"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={contactIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/payment" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Payments"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={paymentIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
          <Grid item lg={3} md={3} xs={6}>
            <LinkRouter to="/visa" style={{ textDecoration: 'none' }}>
              <CardActionArea>
                <Card
                  title="Visa"
                  icon={
                    <CardMedia
                      component="img"
                      alt="menu-icon"
                      image={processingIcon}
                    />
                  }
                />
              </CardActionArea>
            </LinkRouter>
          </Grid>
        </Grid>
      </MenuWrapper>
    </div>
  );
};

export default Menus;
