import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVisaStatus as getVisaStatusRx,
  setLoading as setLoadingRx
} from 'reducers/visaStatus';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import Table from 'components/Table/Table';
import { visaStatusCall } from '../visa-status-utils';
import { CircularLoader } from 'components/Loader/Loader';

const headers = ['Process Steps', 'Status'];

const checkVisaCode = (code) => {
  switch (code) {
    case 'E1':
      return 'Your application is in progress. We will send you a message when we start reviewing your eligibility.';
    case 'E2':
      return 'We are reviewing whether you meet the eligibility requirments.';
    case 'E3':
      return 'Your eligibility has been reviewed. Please see the final decision below';
    case 'M1':
      return 'You do not need a medical exam. We will send you a message if this changes.';
    case 'M2':
      return 'You passed the medical exam.';
    case 'AD1':
      return 'We do not need additional documents.';
    case 'AD2':
      return 'We need additional documents to process your application. We will send you a letter or email with details.';
    case 'AD3':
      return 'The additional documents you provided are being reviewed.';
    case 'IA1':
      return 'You do not need an interview. We will send you a message if this changes.';
    case 'IA2':
      return 'Your interview has been completed.';
    case 'B1':
      return 'We do not need your fingerprint. We will send you a message if this changes';
    case 'B2':
      return 'We need your biometrics to process the application.';
    case 'B3':
      return 'Completed';
    case 'BC1':
      return 'We are processing your background check. We will send you a message if we need more information.';
    case 'BC2':
      return 'Your application is in progress. We will send you a message when we start your background check.';
    case 'BC3':
      return 'Your background check has been completed. Please see the final decision below.';
    case 'FD1':
      return 'Your application is in progress. We will send you a message once the final decision has been made.';
    case 'FD2_APPROVED':
      return 'Your application was approved. You need to send us your valid passport to finalize your application.';
    case 'FD2_REFUSED':
      return 'Your application was refused. We will send you a refusal letter.';
  }
};

const BasePage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.userProfile.isLoading);
  const userProfile = useSelector(
    (state) => state.userProfile.userProfile_info
  );
  const isStatusLoading = useSelector((state) => state.visaStatus.isLoading);
  const visaStatusData = useSelector(
    (state) => state.visaStatus.visaStatus_data
  );
  let data = [];

  useEffect(() => {
    if (!visaStatusData?.length) {
      dispatch(setLoadingRx(true));
      visaStatusCall((data) => dispatch(getVisaStatusRx(data)));
    }
  }, []);

  if (visaStatusData?.length > 0) {
    visaStatusData?.map((status) => {
      data.push({
        step: status?.status,
        status: checkVisaCode(status?.status_code)
      });
    });
  }
  if (isLoading || isStatusLoading) {
    return <CircularLoader />;
  }

  if (userProfile.visa_submitted === 'false') {
    return (
      <AlertBanner
        heading="Notice"
        content="Your application is not submitted to IRCC, check later again"
        variant="error"
      />
    );
  }
  return <Table headers={headers} data={data} maxWidth={'auto'} />;
};

export default BasePage;
