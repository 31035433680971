import React from 'react';
import TextField from 'components/TextField/TextField';

const TextArea = (props) => {
  const textAreaProps = {
    multiline: true,
    type: 'textarea',
    rows: 4
  };
  return <TextField {...textAreaProps} {...props} />;
};

export default TextArea;
