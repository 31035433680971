import React from 'react';
import PropTypes from 'prop-types';
import { Typography as TypographyMui } from '@mui/material';

const Typography = ({
  align,
  component,
  gutterBottom,
  noWrap,
  paragraph,
  sx,
  variant,
  variantMapping,
  children,
  ...props
}) => {
  const typographyProps = {
    align,
    component,
    gutterBottom,
    noWrap,
    paragraph,
    sx,
    variant,
    variantMapping,
    ...props
  };

  return <TypographyMui {...typographyProps}>{children}</TypographyMui>;
};

Typography.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  paragraph: PropTypes.bool,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ])
};

Typography.defaultProps = {
  align: 'inherit',
  variant: 'body1',
  gutterBottom: false,
  noWrap: false,
  paragraph: false
};

export default Typography;
