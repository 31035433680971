export const palette = {
  light: {
    primary: {
      main: '#DD1C1A'
    },
    secondary: {
      main: '#11cb5f'
    },
    gray: {
      main: '#eeeeee'
    }
  }
};
