import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  profile_info: {}
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getProfileInfo: (state, action) => {
      state.isLoading = true;
      state.profile_info = action.payload;
      state.isLoading = false;
    },
    clearProfile: () => initialState
  }
});

export const { setLoading, getProfileInfo, clearProfile } =
  profileSlice.actions;

export default profileSlice.reducer;
