import Table from 'components/Table/Table';
import { useSelector } from 'react-redux';
import { CircularLoader } from 'components/Loader/Loader';

const headers = ['Fields', 'Values'];

const ProfileDetails = () => {
  const profile_info = useSelector((state) => state.profile.profile_info);
  const isLoading = useSelector((state) => state.profile.isLoading);

  let data;
  if (profile_info?.id) {
    data = [
      { field: 'First Name', value: profile_info?.first_name },
      { field: 'Last Name', value: profile_info?.last_name },
      { field: 'Email', value: profile_info?.email },
      { field: 'Date of Birth', value: profile_info?.date_of_birth },
      { field: 'Gender', value: profile_info?.gender },
      { field: 'Passport No', value: profile_info?.passport_no },
      { field: 'Phone No', value: profile_info?.phone_no },
      { field: 'Address', value: profile_info?.address },
      {
        field: 'Emergency Contact',
        value: profile_info?.eme_contact
      }
    ];
  }
  return (
    <>
      {isLoading && <CircularLoader />}
      {!isLoading && profile_info?.id && (
        <Table headers={headers} data={data} />
      )}
    </>
  );
};

export default ProfileDetails;
