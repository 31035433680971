import * as React from 'react';
import { emphasize, styled } from '@mui/material/styles';
import { Breadcrumbs as BreadcrumbsMui, Chip } from '@mui/material';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06)
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12)
    }
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
}

const Breadcrumbs = (props) => {
  return (
    <div role="presentation" onClick={handleClick}>
      <BreadcrumbsMui aria-label="breadcrumb">
        <StyledBreadcrumb
          component="a"
          href="#"
          label={props.label}
          icon={props.icon}
        />
      </BreadcrumbsMui>
    </div>
  );
};

export default Breadcrumbs;
