import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';

const Badge = ({ label, color, variant, size, clickable, sx }) => {
  const chipProps = {
    label,
    color,
    variant,
    size,
    clickable,
    sx
  };
  return <Chip {...chipProps} />;
};

Badge.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning'
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium']),
  clickable: PropTypes.bool
};

Badge.deafultProps = {
  label: undefined,
  color: 'primary',
  variant: 'filled',
  clickable: false
};

export default Badge;
