export function setCookie(cname, cvalue) {
  localStorage.setItem(cname, cvalue);
}

export function getCookie(cname) {
  return localStorage.getItem(cname);
}

export function deleteCookie(name) {
  localStorage.removeItem(name);
}
