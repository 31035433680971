import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  userProfile_info: {}
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getUserProfileInfo: (state, action) => {
      state.isLoading = true;
      state.userProfile_info = action.payload;
      state.isLoading = false;
    },
    clearUserProfileInfo: () => initialState
  }
});

export const { setLoading, getUserProfileInfo, clearUserProfileInfo } =
  userProfileSlice.actions;

export default userProfileSlice.reducer;
