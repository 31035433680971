import { get, post } from './http';

export const login = (email, password) => {
  return post('/login', { email, password });
};

export const getLoggedInUser = () => {
  return get('/user');
};

export const logout = () => {
  return post('/logout');
};

export const register = ({
  first_name,
  last_name,
  email,
  password,
  password_confirmation
}) => {
  return post('/register', {
    first_name,
    last_name,
    email,
    password,
    password_confirmation
  });
};

// user information
export const getUserInfo = () => {
  return get('/profile');
};

// user profile info
export const getUserProfileInfo = () => {
  return get('/user-profile');
};

// payment information
export const getPaymentInfo = () => {
  return get('/payments-info');
};

// document information
export const getDocumentInfo = () => {
  return get('/document-info');
};

// visa status
export const getVisaStatus = () => {
  return get('/visa');
};
