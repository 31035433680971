import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Person2Icon from '@mui/icons-material/Person2';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';

import LeftBar from 'components/LeftBar';

const leftMenuItemsList = [
  {
    title: 'Details',
    icon: <FolderSharedRoundedIcon />,
    path: ''
  }
];

const Profile = () => {
  return (
    <>
      <LeftBar
        topNavbarIcon={<Person2Icon fontSize="small" />}
        topNavbarLabel="Profile"
        leftMenuItems={leftMenuItemsList}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: { sm: '240px' }
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </>
  );
};

export default Profile;
