import { createTheme } from '@mui/material/styles';
import { palette } from './palette';
import typography from './typography';
import { badge } from './badge';

const theme = createTheme({
  palette: palette.light,
  components: {
    MuiChip: badge
  },
  typography
});

export default theme;
