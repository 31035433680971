import React from 'react';
import {
  Paper,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

const Table = ({ headers, data, maxWidth }) => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth }}>
      <TableMui aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((tit) => (
              <TableCell key={tit} sx={{ fontSize: '18px', fontWeight: '500' }}>
                {tit}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, id) => (
            <TableRow
              hover
              key={id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {Object.values(row).map((value, id) => (
                <TableCell key={id}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </TableMui>
    </TableContainer>
  );
};

Table.defaultProps = {
  maxWidth: 550
};

export default Table;
