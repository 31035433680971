import { redirect, useLocation, Navigate } from 'react-router-dom';
import { AUTH_TOKEN_COOKIE_NAME } from 'constants/cookies';
import { LOGIN_PAGE } from 'constants/urls';
import { getCookie } from './cookies';
import { getLocalStorage } from './localStorage';

export const checkAuthLoader = () => {
  const token = getCookie(AUTH_TOKEN_COOKIE_NAME);

  if (!token) {
    return redirect(LOGIN_PAGE);
  }

  return null;
};

export const ActiveRouteProtect = ({ children }) => {
  const location = useLocation();
  const isActiveUser = getLocalStorage('isActive') === 'true';

  if (!isActiveUser && location.pathname !== '/home') {
    return <Navigate to="/home" />;
  }
  return children;
};
