import * as React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonMui } from '@mui/material';

const Button = ({
  variant,
  onClick,
  to,
  color,
  disabled,
  size,
  startIcon,
  endIcon,
  component,
  children,
  external,
  sx,
  ...props
}) => {
  const buttonProps = {
    variant,
    onClick,
    to,
    color,
    disabled,
    size,
    startIcon,
    endIcon,
    sx,
    component,
    ...props
  };
  return <ButtonMui {...buttonProps}>{children}</ButtonMui>;
};

Button.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning'
  ]),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};

Button.defaultProps = {
  variant: 'contained'
};

export default Button;
