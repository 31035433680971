import * as React from 'react';
import {
  Card as CardMui,
  CardHeader,
  CardContent,
  Typography
} from '@mui/material';

const Card = ({ title, notification, icon, text, isChart, children }) => {
  return (
    <CardMui sx={{ borderRadius: 3 }} variant="outlined">
      <CardHeader
        sx={{ paddingBottom: 0 }}
        title={
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
        }
        action={notification}
      />
      {!isChart && (
        <CardContent
          sx={{
            paddingTop: 1
          }}
        >
          {icon}
          {text}
        </CardContent>
      )}
      {isChart && (
        <CardContent
          sx={{
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            height: '230px'
          }}
        >
          {children}
        </CardContent>
      )}
    </CardMui>
  );
};

export default Card;
