import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Table from 'components/Table/Table';
import { CircularLoader } from 'components/Loader/Loader';
import Badge from 'components/Badge/Badge';

const headers = ['Required Payments', 'Amount', 'Status'];

const PaymentInfo = () => {
  let data = [];
  const payment_info = useSelector((state) => state.paymentInfo.payment_info);
  const isLoading = useSelector((state) => state.paymentInfo.isLoading);

  if (payment_info?.length > 0) {
    payment_info?.map((payment) => {
      let amount = payment.amount.split(' ');
      data.push({
        payment: payment.payment,
        amount: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: amount[0]
        }).format(+amount[1]),
        status:
          payment.status === 'on' ? (
            <Badge label="Received" color="success" size="small" />
          ) : (
            <Badge label="Not Received" color="error" size="small" />
          )
      });
    });
  }

  return (
    <Fragment>
      {isLoading && <CircularLoader />}
      {!isLoading && <Table headers={headers} data={data} />}
    </Fragment>
  );
};

export default PaymentInfo;
