import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  payment_info: []
};

export const paymentInfoSlice = createSlice({
  name: 'paymentInfo',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getPaymentInfo: (state, action) => {
      state.isLoading = true;
      state.payment_info = action.payload;
      state.isLoading = false;
    },
    clearPaymentInfo: () => initialState
  }
});

export const { setLoading, getPaymentInfo, clearPaymentInfo } =
  paymentInfoSlice.actions;

export default paymentInfoSlice.reducer;
