import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  SwipeableDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';

import NavBar from 'components/NavBar';
import LineBreak from 'components/LineBreak/LineBreak';
// style
import styles from './LeftBar.module.scss';

const drawerWidth = 240;

const LeftBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List className={styles.list}>
        {props.leftMenuItems.map((list, index) => (
          <React.Fragment key={index}>
            <NavLink
              to={list.path}
              className={styles['nav-link']}
              onClick={handleDrawerToggle}
            >
              <ListItemButton>
                <ListItemIcon>{list.icon}</ListItemIcon>
                <ListItemText primary={list.title} />
              </ListItemButton>
              <LineBreak />
            </NavLink>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <NavBar
          labelIcon={props.topNavbarIcon}
          label={props.topNavbarLabel}
          leftMenu={
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          }
        />
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 }
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <SwipeableDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onOpen={toggleDrawer('left', true)}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </SwipeableDrawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default LeftBar;
